import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetch = async (api, search) => {
    try {
        if(!search)
            return []
            
        const response = await fetcherReactQuery(api)
        return response.data
    }catch(err)
    {
        console.log(err)
        return []
    }
}
const useGaugesByPeriodMonth = (period, month, search) => {
    return useQuery([`/api/registroindicadores/${period}/${month}`, search], fetch)
}
export default useGaugesByPeriodMonth