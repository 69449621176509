import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, Select,  FormControl, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: "20px",
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const CustomSelect = props => {
    const classes = useStyles()
    const {id, data, onChange, placeholder, textField, valueField, label, value, error, helperText } = props
    
    const handleChange = (event) => {
        onChange(event.target.value)
    }

    if(!data)
        return <> </>;

    return (
        <FormControl className={classes.formControl} error={error}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                native
                value={value}
                onChange={handleChange}
                inputProps={{
                    name: {id},
                    id: {id},
                }}
            >
                <option aria-label="None" key="-1" value="" > {placeholder}</option>
                {data && data.map( item => {
                    return (
                        <option key={item[valueField]} value={item[valueField]}>{item[textField]}</option>
                    )
                })}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

CustomSelect.default = {
    error: false
}

CustomSelect.propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func,
    textField: PropTypes.string,
    valueField: PropTypes.object,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool
}
    

export default CustomSelect
    