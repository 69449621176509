import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Fab, Grid, TextField } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { fetchMutationBankInfo } from '../../../components/hooks/useApplicationBankInfo';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import Select from '../../../components/select';
import useBancos from '../../../components/hooks/useBancos';
import validationSchema from './validation';

const BankInfo = props => {
    const { solicitud } = props;
    const navigate = useHistory();
    const { data: Bancos } = useBancos();
    const [mutate] = useMutation(fetchMutationBankInfo, {
        onSuccess: () => {
            formik.setSubmitting(false);
            toast.success("Los datos bancarios se han remplazado exitosamente", {
                autoClose: 2000
            })
            navigate.goBack();
        },
        onError: (err) => {
            formik.setSubmitting(false);
            toast.error(err.response.data.Message, {
                autoClose: 5000
            })
        }
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: solicitud,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSave = {
                Id: values.Id,
                BancoId: values.BancoId,
                NumeroCuenta: values.NumeroCuenta,
                CLABE: values.CLABE,
            }
            mutate(toSave);
        }
    });


    return (
        <>
            <Grid item xs={12}>
                {formik.isSubmitting &&
                    <Box display="flex" justifyContent="center">
                        <CircularProgress size={60} title="Procesando" />
                    </Box>
                }
            </Grid>
            {!formik.isSubmitting &&
                <Grid>
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <>
                            <Grid item sm={12}>
                                <Select
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    label="Banco"
                                    id="BancoId"
                                    onChange={e => {
                                        formik.setFieldValue("BancoId", +e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    data={Bancos || []}
                                    textField="NombreCorto"
                                    valueField="Id"
                                    placeholder="Bancos"
                                    value={formik.values.BancoId}
                                    error={formik.errors.BancoId}
                                    helperText={formik.errors.BancoId}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    label="CLABE / No. Tarjeta / No. Cuenta"
                                    name="CLABE"
                                    id="CLABE"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.CLABE}
                                    error={formik.errors.CLABE}
                                    helperText={formik.errors.CLABE}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "right", marginTop: 10 }}>
                                <Fab type="submit"
                                    size="medium"
                                    color="primary"
                                    title="Grabar solicitud"
                                    disabled={formik.isSubmitting || formik.errors.length > 0}
                                >
                                    <SaveIcon />
                                </Fab>
                            </Grid>
                        </>
                    </form>
                </Grid>
            }
        </>
    )
}

BankInfo.propTypes = {
    solicitud: PropTypes.object.isRequired
}

export default BankInfo;
