import axios from '../auth/axios-service'


export const post = async (url, body) => {
    return await axios.post(url,
        body, {
        headers: {
            'Authorization': `Bearer ${ window ? localStorage.getItem("token") : null}`,
            'Access-Control-Allow-Origin': '*'
        }
    })
}