import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    monto: Yup.string()
        .required("El monto del préstamo es requerido"),
        

    montodescuento: Yup.string()
        .required("El monto del descuento es requerido"),

    montodescuentocompensacion: Yup.string()
})

export default validationSchema
