// import { fetcherSWR } from '../../services/api/fetcher'
// import useSWR from 'swr'
// const fetcher = async (api) => {
//     return await fetcherSWR(api)
// }
// const useLoanSavingsAccountStatement = (empresaConvenioId, userId, financialProductTypeId, firstDateYear, currentDate) => {
//     return useSWR([`/api/registrarprestamosahorro/estadocuenta/${empresaConvenioId}/${userId}/${financialProductTypeId}/${firstDateYear}/${currentDate}`], fetcher)
// }
// export default useLoanSavingsAccountStatement


import { fetcherReactQuery } from '../../services/api/fetcher';
import { useQuery } from 'react-query';

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}

const useLoanSavingsAccountStatement = (empresaConvenioId, userId, financialProductTypeId, firstDateYear, currentDate) => {
    return useQuery([`/api/registrarprestamosahorro/estadocuenta/${empresaConvenioId}/${userId}/${financialProductTypeId}/${firstDateYear}/${currentDate}`], fetch)
}

export default useLoanSavingsAccountStatement

