import { fetcherReactQuery } from '../../services/api/fetcher';
import { useQuery } from 'react-query';

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}

const usePeriodoByDate = (currentDate) => {
    return useQuery([`/api/periodos/19/${currentDate}`], fetch, {
        staleTime: 300000
    })
}

export default usePeriodoByDate;


