import React from 'react';
import Helmet from 'react-helmet';
import Heading from '../components/typography/heading';
import PaperCard from '../components/paper';
import AuthContext from '../components/auth/context';
import { useAuth } from '../components/auth/useAuth';
import PendingApplicationView from '../views/pending-application-view/pending-application-view';

const PendingApplication = props => {
    const { UserInfo } = React.useContext(AuthContext);
    const userAuth = useAuth(UserInfo());
    
    return (
        <>
            <Helmet title={'Solicitudes Pendientes'} />
            <Heading  text={"Solicitudes Pendientes"}/>
            <PaperCard>
                {userAuth && userAuth.sub && <PendingApplicationView  userAuth={userAuth} />} 
            </PaperCard>
        </>
    )
}

export default PendingApplication
