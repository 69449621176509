import React from 'react'
import useStates from '../hooks/useStates'
import { CircularProgress } from '@material-ui/core'
import Select from '../select'
import PropTypes from 'prop-types'

const StatesSelect = (props) => {
    const { onStateChange, estadoid, primitive } = props;
    const [ state, setState] = React.useState(null);
    const { data, isLoading } = useStates();
        
    React.useEffect(() => {
        if(estadoid)
            setState(estadoid)        
    }, [estadoid])

    const onChange = (value) => {
        const selected = data.find(item => item.Id === +value );
        setState(value);
        onStateChange(primitive ? value : selected);
    }
    
    if(isLoading)
        return <CircularProgress />

    return (
        <Select
            id={"Estado"}
            data={data}
            textField="Nombre"
            valueField="Id"
            label="Estado"
            placeholder="Seleccionar"
            value={state}
            onChange={onChange}
        />
    )
}

StatesSelect.default = {
    primitive: true
}

StatesSelect.propTypes = {
    onStateChange : PropTypes.func,
    estadoid: PropTypes.string,
    primitive: PropTypes.bool
}

export default StatesSelect
