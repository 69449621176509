import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingBottom: "15px"
    }
}));

const Heading = ({text}) => {
    const classes = useStyles()
    return (
        <Typography
            className={classes.heading}
            component="h1"
            variant="h6"
            color="primary">{text}
        </Typography>
    )
}

export default Heading
