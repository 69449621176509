import React from 'react';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import Heading from '../components/typography/heading';
import { useAuth } from '../components/auth/useAuth';
import AuthContext from '../components/auth/context';
import { PaperCard } from '../components';
import { CircularProgress } from '@material-ui/core';
import { fetchApplicationSign } from "../components/hooks/useApplicationSign";
import { useQuery } from 'react-query';
import Sign from '../views/pending-application-view/components/sign';
import SolicitudHeader from '../views/pending-application-view/components/solicitud-header';

const ApplicationSign = () => {
    const params = useParams();
    const [id, setId] = React.useState(null);
    const { UserInfo } = React.useContext(AuthContext);
    const userAuth = useAuth(UserInfo());
    const { data, isLoading } = useQuery(['application-sign', id], () => fetchApplicationSign(id || 0));
    
    React.useEffect(() => {
        if(params)
            setId(+params.id);    
    }, [params]);
    
    return (
        <>
            <Helmet title={'Corrección de Firma Digital'} />
            <Heading text={"Corrección de Firma Digital"} />
                {userAuth && userAuth.sub &&  
                <PaperCard>
                    {
                        isLoading ?
                            <CircularProgress /> :
                            (
                                <SolicitudHeader item xs={12}>
                                        <Sign solicitud={data} />
                                </SolicitudHeader>
                            )
                    }
                </PaperCard>
            }
        </>
    )
}
export default ApplicationSign
