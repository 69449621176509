import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}
const useCampanas = (EmpresaConvenioId) => {
    return useQuery([`/api/campanas/active/${EmpresaConvenioId}`], fetch, {
        refetchOnWindowFocus: false,
        staleTime: 30000

    })
}
export default useCampanas;