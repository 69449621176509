import React from 'react'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import AccountStatusInfo from '../views/accountstatus'
import { Helmet } from 'react-helmet'
import Heading from '../components/typography/heading'

const AccountStatus = (props) => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())
    return (
        <div>
            <Helmet title={'Estado de Cuenta del Préstamo'} />
            <Heading  text={"Estado de Cuenta del Préstamo"}/>
            {userAuth && userAuth.sub && <AccountStatusInfo userAuth={userAuth} />}
        </div>
    )
}

export default AccountStatus
