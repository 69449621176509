import React from 'react'
import PropTypes from 'prop-types'
import SignUpRfc from './components/sign-up-rfc';
import SignUpEmail from './components/sign-up-email';

const Index = (props) => {
    const oauth_type = process.env.REACT_APP_OAUTH;
    return (oauth_type === "RFC" ? <SignUpRfc classes={props.classes} login={props.login}/> : <SignUpEmail classes={props.classes} login={props.login} />)
}

Index.propTypes = {
    classes: PropTypes.object,
    login: PropTypes.func
}

export default Index
