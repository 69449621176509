import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import  SavingInfo  from '../views/saving'
import AuthContext from '../components/auth/context'

const SavingInformation = () => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())

    return (
        <div>
            <Helmet title={'Información del Ahorro'} />
            <Heading  text={"Información del Ahorro"}/>
            {userAuth && userAuth.sub && <SavingInfo userAuth={userAuth} />}
        </div>
    )
}

export default SavingInformation
