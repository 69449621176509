import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const CheckboxList = (props) => {
    const { items, onChange, checkedItems } = props
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        if(onChange)
            onChange(newChecked);
    };

    React.useEffect(() => {        
        setChecked(checkedItems);        
    }, [checkedItems]);

    return (
        <List className={classes.root}>
            {items?.map(({value, text }) => {
                const labelId = `checkbox-list-label-${text}`;

                return (
                    <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={text} /> 
                    </ListItem>
                );
            })}
        </List>
    );
}

CheckboxList.propTypes = {
    items: PropTypes.array.isRequired,
    checkedItems: PropTypes.array.isRequired,
    onChange: PropTypes.func
}
export default CheckboxList;