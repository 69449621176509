import { fetcherReactQuery, MutationReactQuery } from '../../services/api/fetcher';

const fetchApplicationSign = async (id) => {
    const response = await fetcherReactQuery(`/api/solicitudadmin/${id}/firma`);
    return response.data;
}

const fetchMutationSign = async (solicitud) => {
    const response = await MutationReactQuery(
        "post",
        `api/solicitudadmin/${solicitud.Id}/firma`,
        solicitud)
    return response.data
}

export { 
    fetchApplicationSign,
    fetchMutationSign
}