import React from 'react'
import PropTypes from 'prop-types';
import { Box, Fab, Grid } from '@material-ui/core';
import { Delete, Done } from '@material-ui/icons';
import SignaturePad from 'react-signature-canvas';

const SignPad = ({ onSignChanged, onClear, disabled, error }) => {
    const sigPad = React.useRef(null);

    return (
        <Grid>
            <Grid item sm={12}>
                <Box display="flex" justifyContent={"center"}>
                    <div style={{
                        border: error ? "1px solid red" : "1px solid #8899A8",
                        display: "inline-block"
                    }}>
                        <SignaturePad
                            ref={(ref) => { sigPad.current = ref }}
                            canvasProps={{
                                className: "signatureCanvas"
                            }}

                        />
                    </div>
                </Box>
            </Grid>
            <Grid item sm={12} style={{ marginTop: 10 }}>
                <Box display="flex" justifyContent={"center"}>
                    <Fab size="medium"
                        disabled={disabled}
                        style={{ marginRight: 10}}
                        color="primary"
                        title="Aceptar firma"
                        onClick={e => {
                            const url = sigPad.current.getTrimmedCanvas()
                                .toDataURL('image/png');
                            
                            if(url !== "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC")
                                onSignChanged(url);
                            else
                                onSignChanged(null);
                        }}
                    >
                        <Done />
                    </Fab>
                    <Fab size="medium"
                        disabled={disabled}
                        color="secondary"
                        title="Eliminar firma"
                        onClick={e => {
                            sigPad.current.clear();
                            onClear(null);
                        }}
                    >
                        <Delete />
                    </Fab>
                </Box>
            </Grid>
        </Grid>
    )
}

SignPad.default = {
    disabled: false,
    error: false
}

SignPad.propTypes = {
    onSignChanged: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}

export default SignPad;
