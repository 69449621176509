import React from 'react'
import { Fab } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import PropTypes from 'prop-types'

const EmailButton = (props) => {
    const { onClick } = props
    return (
        <Fab color="primary" aria-label="add" size="small" onClick={onClick}>
            <EmailIcon />
        </Fab>
    )
}

EmailButton.propTypes = {
    onClick: PropTypes.func
}

export default EmailButton
