import { fetcherReactQuery } from '../../services/api/fetcher';
import { useQuery } from 'react-query';

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}

const useCities = (stateid) => {
    return useQuery([`/api/ObtenerMunicipioDD/${stateid}`], fetch, {
        staleTime: 30000
    })
}

export default useCities

