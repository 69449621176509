import AssignmentIcon from '@material-ui/icons/Assignment'

import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import MoneyIcon from '@material-ui/icons/Money'
import BarChartIcon from '@material-ui/icons/BarChart'
import { List as ListIcon,
        PlaylistAddCheck as PlaylistAddCheckIcon,
        AssignmentInd as ProfileIcon,
        Functions as SimuladorIcon } from '@material-ui/icons';


const menus = [
    {
        text: 'Solicitudes',
        Icon: PlaylistAddCheckIcon,
        to: "/admin/pending-application",
        roles: ["empleado"]
    },
    {
        text: 'Perfil del Empleado',
        Icon: ProfileIcon,
        to: "/admin/profile",
        roles: ["empleado"]
    },
    {
        text: 'Historial de Solicitudes',
        Icon: ListAltIcon,
        to: "/admin/loan-history",
        roles: ["empleado"]
    },
    {
        text: 'Historial de Prestamos',
        Icon: ListIcon,
        to: "/admin/application-history",
        roles: ["empleado"]
    },
    {
        text: 'Simulador',
        Icon: SimuladorIcon,
        to: "/admin/loan-calculator",
        roles: ["empleado"]
    },
    {
        text: "Estado de Cuenta del Prestamo",
        Icon: MoneyIcon,
        to: "/admin/account-status",
        roles: ["empleado"]
    },
    {
        text: "Estado de Cuenta del Ahorro",
        Icon: MonetizationOnIcon,
        to: "/admin/savings-account-statetment",
        roles: ["empleado"]
    },
    {
        text: "Información del Ahorro",
        Icon: AssignmentLateIcon,
        to: "/admin/saving-info",
        roles: ["empleado"]
    },
    {
        text: "Reporte Informativo Trimestral",
        Icon: AssignmentIcon,
        to: "/admin/quarterly-report",
        roles: ["empleado"]
    },
    {
        text: "Consulta de Estado de Cuenta",
        Icon: ListAltIcon,
        to: "/admin/query-accountstatus",
        roles: ["admin", "admon"]
    },
    {
        text: "Consulta de Estado de Cuenta del Ahorro",
        Icon: MonetizationOnIcon,
        to: "/admin/query-account-savings",
        roles: ["admin", "admon"]
    },
    {
        text: "Consulta de Indicadores",
        Icon: BarChartIcon,
        to: "/admin/query-gauges",
        roles: ["admin", "admon"]
    }
]

export default menus