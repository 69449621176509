import React from 'react'
import PropTypes from 'prop-types'
import SignInEmail from './components/sign-in-email';
import SignInRfc from './components/sign-in-rfc';

const Index = (props) => {
    const { classes, login, onChangeView, UserInfo } = props;
    const oauth_type = process.env.REACT_APP_OAUTH;
    return (oauth_type === "RFC" ? <SignInRfc onChangeView={onChangeView} classes={classes} login={login} UserInfo={UserInfo} /> : <SignInEmail onChangeView={onChangeView} classes={classes} login={login} UserInfo={UserInfo}/>)

    
}

Index.propTypes = {
    classes: PropTypes.object,
    login: PropTypes.func,
    onChangeView: PropTypes.func,
    UserInfo: PropTypes.func
}

export default Index
