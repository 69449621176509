import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Introducir un correo electrónico correcto')
        .required("Correo electrónico requerido")
        .max(255, "El correo no debe ser mayor a 255 caracteres"),

    password: Yup.string()
        .min(8, "La contraseña debe ser por lo menos de 8 caracteres")
        .required("Contraseña requerida")
})

export default validationSchema