import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}
const useBancos = () => {
    return useQuery([`/api/bancos/activos`], fetch, {
        refetchOnWindowFocus: false,
        staleTime: 30000
    })
}
export default useBancos;