import { fetcherReactQuery, MutationReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetch = async (api) => {
    try {
        const response = await fetcherReactQuery(api ? api : null)
        return response.data
    } catch (err) {
        console.log(err.message)
    }

}
const useApplicationDocuments = (id) => {
    return useQuery([`api/solicitudadmin/${id}/comprobantes`], fetch)
}


const fetchMutationDocuments = async (solicitud) => {
    const response = await MutationReactQuery(
        "post",
        `api/solicitudadmin/${solicitud.Id}/comprobantes`,
        solicitud)
    return response.data
}

export {
    useApplicationDocuments,
    fetchMutationDocuments
};