import React from 'react'
import PropTypes from 'prop-types'
import { PaperCard } from '../../components'
import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, Grid, CircularProgress } from '@material-ui/core'
import AccountStatusTable from './table'
import { formatNumber } from 'intl-number-helper'
import EmailButton from '../../components/button/emailButton'
import useFinancialProductType from '../../components/hooks/useFinancialProductType'
import { fetcherSWR } from '../../services/api/fetcher'
import CustomSelect from '../../components/select';
import { toast } from 'react-toastify';
import useAccountStatus from '../../components/hooks/useAccountStatus'

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
    },
    value: {
        fontSize: "14px",
        color: theme.palette.grey[600],
    }
}))

const getFinalBalance = (accountStatus) => {
    return accountStatus ? accountStatus.length > 0 ? accountStatus[accountStatus.length - 1].Saldo : 0 : 0
}

const Index = props => {
    const { userAuth } = props
    const classes = useStyles()
    const [tipoProducto, setTipoProducto] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [saldo, setSaldo] = React.useState(0)
    const { data: tipoprestamos, error, isLoading } = useFinancialProductType(userAuth.IdEmpresaConvenio);

    const { isLoading: isLoadingTable, data: accountsData } = useAccountStatus(userAuth.IdEmpresaConvenio, userAuth.sub, tipoProducto ?? 0);

    React.useEffect(() => {
        if (tipoprestamos && tipoprestamos.length > 0)
            setTipoProducto(tipoprestamos[0].IdProductoFinanciero)
    }, [tipoprestamos])

    React.useEffect(() => {
        setSaldo(getFinalBalance(accountsData))
    }, [accountsData])

    const containsData =() => {
        return accountsData?.length > 0;
    }

    if (error)
        return <p>error</p>

    
    const onClickHandler = () => {
        const first = tipoprestamos[0]
        setLoading(true);
        fetcherSWR(`/api/SendEmailEstadoCuenta/${userAuth.IdEmpresaConvenio}/${userAuth.sub}/${first.IdProductoFinanciero}`).then(data => {
            setLoading(false);
        }).catch((err) => {
            toast.error(err?.response?.data?.Message)
            setLoading(false);
        })
    }

    return (
        <PaperCard>
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <FormLabel className={classes.label}>Tipo de Préstamo</FormLabel>
                </Grid>
                <Grid item>
                    {isLoading && <CircularProgress />}
                    {!isLoading && <CustomSelect
                        data={tipoprestamos}
                        valueField={'IdProductoFinanciero'}
                        textField={"Nombre"}
                        label="Tipo de Préstamos"
                        value={tipoProducto}
                        helperText="Selecciona el tipo de préstamo"
                        onChange={e => setTipoProducto(e)}
                    ></CustomSelect>
                    }
                </Grid>
                <Grid item>
                    <FormLabel className={classes.label}>Saldo</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.value}>{formatNumber(saldo, "en-US", "$")}</FormLabel>
                </Grid>
                <Grid container justify={"flex-end"}>
                    {loading ? <CircularProgress /> : containsData() ? <EmailButton onClick={onClickHandler} /> : ""}
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: "center" }}>
                    {isLoadingTable && <CircularProgress />}
                </Grid>
                <Grid item xs={12}>
                    <AccountStatusTable data={accountsData} />
                </Grid>
            </Grid>
        </PaperCard>
    )
}



Index.propTypes = {
    userAuth: PropTypes.object.isRequired
}

export default Index
