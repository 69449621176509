import React from 'react'
import PropTypes from 'prop-types'
import { formatNumber } from 'intl-number-helper'
import { Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from  '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useLoanSavingsAccountStatement from '../../components/hooks/useLoanSavingsAccountStatement'
import TableResponsive from '../../components/tableresponsive'

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: "bold"
    }
}))

const SavingsAccountStatement = props => {
    const { empresaconvenioid, userid, savingPlan, currentDate, firstDateYear, onSaldoChange } = props
    const classes = useStyles();
    const { data, error, isLoading } = useLoanSavingsAccountStatement(
        empresaconvenioid,
        userid, 
        savingPlan.TipoProductoFinancieroId,
        firstDateYear, currentDate)

    React.useEffect(() => {
        if(onSaldoChange)
            onSaldoChange(data)
    }, [data, onSaldoChange])

    if(error)
        return <p>error</p>

    if(isLoading)
        return <CircularProgress />

    return (
        <TableResponsive>
            <Table style={{overflowX: "auto"}} aria-label="account status">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.header}>Fecha </TableCell>
                        <TableCell className={classes.header}>Concepto</TableCell>
                        <TableCell align="right" className={classes.header}>Cargo</TableCell>
                        <TableCell align="right" className={classes.header}>Abono</TableCell>
                        <TableCell align="right" className={classes.header}>Saldo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.Folio}>
                            <TableCell align="center" component="th" scope="row">
                                {row.FechaMovimiento}
                            </TableCell>
                            <TableCell align="left">{row.Concepto}</TableCell>
                            <TableCell align="right">{formatNumber(row.Cargo, 'en-US', '$' )}</TableCell>
                            <TableCell align="right">{formatNumber(row.Abono,'en-US','$')}</TableCell>
                            <TableCell align="right">{formatNumber(row.Saldo,'en-US','$')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableResponsive>
    )
}

SavingsAccountStatement.propTypes = {
    savingPlan: PropTypes.object.isRequired,
    currentDate: PropTypes.string.isRequired,
    firstDateYear: PropTypes.string.isRequired,
    onSaldoChange: PropTypes.func,
    empresaconvenioid : PropTypes.string,
    userid: PropTypes.string
}   

export default SavingsAccountStatement
