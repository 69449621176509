import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import QueryAccountSavingsStatementsView from '../views/query-account-saving-statements'

const QueryAccountSavingsStatements = (props) => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())
    return (
        <>
            <Helmet title={'Consulta del Estado de Cuenta del Ahorro por Empleado'} />
            <Heading text={"Consulta del Estado de Cuenta del Ahorro por Empleado"}/>
            {userAuth && userAuth.sub && <QueryAccountSavingsStatementsView userAuth={userAuth} />}
        </>
    )
}

export default QueryAccountSavingsStatements
