import axios from './axios-service'
import qs from 'qs'
import inMemoryJWT from './inMemoryJWTManager '

export const login = async ({ username, password }) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type':'application/x-www-form-urlencoded', 
        }
    }
    try{ 

        const data = qs.stringify({
            username: username,
            password: password,
            'grant_type': "password"
        })
        const response = await axios.post("token", data, {
            headers: config.headers
        })
        
        inMemoryJWT.setToken(response.data.access_token)
        if(window)
            localStorage.setItem("token", response.data.access_token)
        
        let _user = {
            userName: response.data.userName,
            sub: response.data.sub,
            givenName: response.data.givenName,
            numEmpleado: response.data.numEmpleado,
            expires: response.data[".expires"],
            expires_in: response.data.expires_in,
            roles: response.data.roles
        }

        if(response.data.numEmpleado === -1){
            if(window)
                localStorage.setItem("user", JSON.stringify(_user))
            
            inMemoryJWT.setUser(_user)
            return true
        }
        
        const _employee = await axios.get(`/api/GetInfoUsuario/${response.data.sub}`, {
            headers: {
                'Authorization': 'Bearer ' + response.data.access_token,
                'Access-Control-Allow-Origin': '*'
            }
        })
        
        _user.IdEmpresaConvenio = _employee.data.IdEmpresaConvenio
        
        if(window)
            localStorage.setItem("user", JSON.stringify(_user))
        inMemoryJWT.setUser(_user)
        

    }catch(err) {
        console.log(err.message)
        return false
    }
    return true
}

export const logout = () => {
    inMemoryJWT.ereaseToken()
    inMemoryJWT.ereaseUser()
    
    if (window) {
        localStorage.removeItem("token")
        localStorage.removeItem("user")
    }
}

export const isLogedIn = () => {
    let current_time = new Date()// / 1000
    let tokenDate = null
    try {
        if (inMemoryJWT.getToken()) {
            const jwt = JSON.parse(localStorage.getItem("user"))
            tokenDate = new Date(jwt.expires)
            const compare = tokenDate.getTime() > current_time.getTime()
            return compare
        }
        else
            if (localStorage.getItem("token")) {
                const jwt = JSON.parse(localStorage.getItem("user"))
                tokenDate = new Date(jwt.expires)
                const compare = tokenDate.getTime() > current_time.getTime()
                return compare
            }
            else
                return false
    } catch (err) {
        return false
    }
}

export const UserInfo = () => {
    if(isLogedIn()) {
        return getInfo()  
    }
}

export const getToken = () => {
    try {
        if (inMemoryJWT.getToken())
            return inMemoryJWT.getToken()
        else
            if (localStorage.key("token"))
                return localStorage.getItem("token")
            else
                return null
    } catch (err) {
        return false
    }
}

const getInfo = () => {
    if (inMemoryJWT.getUser()) {
        return inMemoryJWT.getUser()
    }

    if (window) {
        if (localStorage.key("user")) {
            return JSON.parse(localStorage.getItem("user"))
        }
    }

    return null
}