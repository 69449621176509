import React from 'react'
import PropTypes from 'prop-types'
import { formatNumber } from 'intl-number-helper'
import { Table, TableBody, TableHead, TableRow, TableCell, ListItem, FormLabel, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableResponsive from '../../components/tableresponsive'

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: "bold"
    },
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "14px",
        },
    },
    value: {
        fontSize: "14px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "12px",
        },
        color: theme.palette.grey[600],
    },
    Division: {
        borderBottom: "1px solid #3F51B5"
    }
}))

const Header = (props) => {
    const { loanInfo, numPagos } = props
    const classes = useStyles()
    return (
        <ListItem>
            <Grid container xs={12}>
                <Grid container>
                    <Grid item xs={9} md={2}>
                        <FormLabel className={classes.label}>Monto Solicitado</FormLabel>
                    </Grid>
                    <Grid item xs={3} md={10}>
                        <FormLabel className={classes.value}>{formatNumber(loanInfo.MontoPrestamo, 'en-US', '$')}</FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9} md={2}>
                        <FormLabel className={classes.label}>Prima de Apertura</FormLabel>
                    </Grid>
                    <Grid item xs={3} md={10}>
                        <FormLabel className={classes.value}>{formatNumber(0, 'en-US', '$')}</FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9} md={2}>
                        <FormLabel className={classes.label}>No. Descuentos</FormLabel>
                    </Grid>
                    <Grid item xs={3} md={10}>
                        <FormLabel className={classes.value}>{numPagos > 0 ? numPagos - 1 : 0}</FormLabel>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    )
}

const AmortizationTable = (props) => {
    const classes = useStyles()
    const { data, loanInfo } = props
    return (
        <div>
            <Header loanInfo={loanInfo} numPagos={data.length} />
            <TableResponsive>
                <Table style={{ overflowX: "auto" }} aria-label="amortization table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={classes.header}>No </TableCell>
                            <TableCell align="right" className={classes.header}>Intereses</TableCell>
                            <TableCell align="right" className={classes.header}>Abono</TableCell>
                            <TableCell align="right" className={classes.header}>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            if (index === 0)
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left" component="th" scope="row">{index}</TableCell>
                                        <TableCell align="right"> - </TableCell>
                                        <TableCell align="right"> - </TableCell>
                                        <TableCell align="right"> {formatNumber(row.Intereses, 'en-US', '$')} </TableCell>
                                    </TableRow>
                                )

                            return (
                                <TableRow key={index}>
                                    <TableCell align="left" component="th" scope="row">{index}</TableCell>
                                    <TableCell align="right">{formatNumber(row.interes, 'en-US', '$')}</TableCell>
                                    <TableCell align="right">{formatNumber(row.PagoTotal, 'en-US', '$')}</TableCell>
                                    <TableCell align="right">{formatNumber(row.SaldoTotal, 'en-US', '$')}</TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableResponsive>
        </div>
    )
}

AmortizationTable.propTypes = {
    data: PropTypes.array,
    loanInfo: PropTypes.object
}

export default AmortizationTable