import React from 'react'
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core'
import { Formik } from 'formik'
import validationSchema from './validation'
import { post } from '../../services/api'

const Form = (props) => {
    const { handleCloseModal, userAuth } = props
    const [externalError, setExternalError] = React.useState(null)
    const onSave = async (payload) => {
        try{
            await post("/api/changepassword", payload);
        }catch(err) {
            setExternalError(err);
        }
    }
    console.log(userAuth);
    return (
        <Formik
            enableReinitialize
            initialValues={{
                user: userAuth.sub,
                email: userAuth.userName,
                oldpass: "",
                newpass: "",
                passwordconfirmation: ""
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                setExternalError(null)
                const { user, email, oldpass, newpass, passwordconfirmation } = values
                await onSave({ user, email, oldpass, newpass, passwordconfirmation })
                setSubmitting(false)
                handleCloseModal()
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
            }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container direction={"column"} spacing={1}>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Contraseña actual"
                                    name="oldpass"
                                    id="oldpass"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.oldpass}
                                    error={touched.oldpass && errors.oldpass}
                                    helperText={errors.oldpass}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Contraseña nueva"
                                    name="newpass"
                                    id="newpass"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newpass}
                                    error={touched.newpass && errors.newpass}
                                    helperText={errors.newpass}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Confirmar contraseña"
                                    name="passwordconfirmation"
                                    id="passwordconfirmation"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.passwordconfirmation}
                                    error={touched.passwordconfirmation && errors.passwordconfirmation}
                                    helperText={errors.passwordconfirmation}
                                />
                            </Grid>
                            <Grid item style={{ marginTop: "20px", textAlign: "right" }} sm={12}>
                                {isSubmitting ? <CircularProgress /> :
                                    (
                                        <>
                                            <Button type="submit" variant="contained" color="primary">
                                                Grabar
                                            </Button>
                                            <Button onClick={handleCloseModal} color="primary">
                                                Cancelar
                                            </Button>
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item>
                                {externalError && <p>{externalError}</p>}
                            </Grid>
                        </Grid>
                    </form>
                )}
        </Formik>
    )
}

Form.propTypes = {

}

export default Form
