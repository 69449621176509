import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Index = ({ title, children, error }) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <fieldset style={{ borderRadius: 5, padding: "20px 30px 20px 10px", borderColor: error ? "red" : '#cac9c9' }}>
            <legend style={{ borderRadius: 5, padding: matches ? "8px 10px" : "10px 20px", backgroundColor: "#757575", color:"white", fontSize: matches ? 12.5 : 18 }}>{title}</legend>
            {children}
        </fieldset>
    )
}

Index.default = {
    error: false
}

Index.propTypes = {
    error: PropTypes.bool,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

export default Index
