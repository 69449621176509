import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    numEmp: Yup.string()
        .required("El número de empleado es requerido"),

    email: Yup.string()
        .email("El correo electrónico no tiene el formato correcto")
        .required("El correo electrónico es requerido")
})

export default validationSchema