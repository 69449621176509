import React from 'react';
import PropTypes from 'prop-types';
import Documents from '../../loan-application/components/documents-edit-mode';
import { Box, CircularProgress, Fab, Grid } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { fetchMutationDocuments } from '../../../components/hooks/useApplicationDocuments';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

const Comprobantes = props => {
    const { solicitud, showCamera } = props;
    const navigate = useHistory();
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [doctos, setDoctos] = React.useState(null);
    const [mutate] = useMutation(fetchMutationDocuments, {
        onSuccess: () => {
            setSubmitting(false);
            setTimeout(() => {
                navigate.goBack();
            }, 1000);
        },
        onError: (err) => {
            console.log(err.response);
            toast.error(err.response.data.Message, {
                autoClose: 5000
            })
        }
    })

    const doctosChange = (doctos) => {
        setDoctos(doctos);
    }

    React.useEffect(() => {
        
        if(solicitud?.Documents)
            setDoctos(solicitud?.Documents.map( doc => {
                const docto = {
                    ...doc,
                    id: doc.Id
                };
                delete docto.Id
                return docto;
            } ));
        
    }, []);

    const saveHandler = (e) => {
        e.preventDefault();
        const _doctos = doctos.map( docto => {
            return {
                DocumentoId: docto?.Documento?.Id,
                Content: docto?.imageDataURL,
                SolicitudId: docto.id,
                NombreArchivo: docto?.Documento?.Descripcion,
                Id: docto.id
            }
        })

        const toSave = {
            Id: solicitud.Id,
            Estatus: 0,
            Comprobantes: _doctos
        }
        
        const missingFiles = doctos.filter(docto => !docto.done);
        if(missingFiles.length > 0) {
            toast.info("Faltan documentos para continuar con el proceso", {
                autoClose: 3000
            });
            return;
        }
        setSubmitting(true);
        mutate(toSave);
    }

    return (
        <>
            <Grid item xs={11}>
                {isSubmitting &&
                    <Box display="flex" justifyContent="center">
                        <CircularProgress size={60}/>
                    </Box>
                }
                {!isSubmitting &&
                    <Documents
                        showCamera={showCamera}
                        editMode={true}
                        onDoctosChange={e => doctosChange(e)}
                        IndTrasferencia={true}
                        data={doctos || []}
                    />
                }
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right", marginTop: 10}}>
                <Fab type="submit"
                    size="medium"
                    color="primary"
                    title="Grabar solicitud"
                    disabled={isSubmitting}
                    onClick={saveHandler}
                >
                    <SaveIcon />
                </Fab>
            </Grid>
        </>
    )
}

Comprobantes.defaultProps = {
    showCamera: false
}

Comprobantes.propTypes = {
    solicitud: PropTypes.object.isRequired,
    showCamera: PropTypes.bool
}

export default Comprobantes;
