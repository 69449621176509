import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const NoData = ({ text }) => <Typography variant="h5">{text}</Typography>

NoData.propTypes = {
    text: PropTypes.string
}

export default NoData
