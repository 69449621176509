import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import QueryGaugeView  from '../views/query-gauges'

const QueryGauges = props => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())
    return (
        <div>
            <Helmet title={'Consulta de Indicadores'} />
            <Heading  text={"Consulta de Indicadores"}/>
            {userAuth && userAuth.sub && <QueryGaugeView userAuth={userAuth} />}
        </div>
    )
}

export default QueryGauges
