import React from 'react'
import { FormLabel, Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { PaperCard } from '../../components'
import { formatNumber } from 'intl-number-helper'
import useEmployee from '../../components/hooks/useEmployee'

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
    },
    value: {
        fontSize: "14px",
        color: theme.palette.grey[600],
    }
}));

const Index = (props) => {
    const classes = useStyles()
    const { userAuth } = props
    const { isLoading, data, error } = useEmployee(userAuth.sub)
    
    if (isLoading)
        return <CircularProgress />

    if (error)
        return <div>error.message</div>

    return (
        <PaperCard>
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <FormLabel className={classes.label}>Empresa/Dependencia</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.value}>{data.Dependencia}</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.label}>Num. Empleado</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.value}>{data.NumEmpleado}</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.label}>Nombre</FormLabel>
                </Grid>
                <Grid item className={classes.value}>
                    <FormLabel className={classes.value}>{data.Busqueda} </FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.label}>Dependencia de Pago</FormLabel>
                </Grid>
                <Grid item className={classes.value}>
                    <FormLabel className={classes.value}>{data.DependenciaPagoStr}</FormLabel>
                </Grid>

                <Grid item>
                    <FormLabel className={classes.label}>Tipo</FormLabel>
                </Grid>
                <Grid item className={classes.value}>
                    <FormLabel className={classes.value}>{data.TipoNomina}</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.label}>Correo Electrónico</FormLabel>
                </Grid>
                <Grid item className={classes.value}>
                    <FormLabel className={classes.value}>{data.CorreoElectronico}</FormLabel>
                </Grid>
                <Grid item>
                    <FormLabel className={classes.label}>Salario</FormLabel>
                </Grid>
                <Grid item className={classes.value}>
                    <FormLabel className={classes.value}>{formatNumber(data.SalarioDiario, 'es-MX', '$')}</FormLabel>
                </Grid>

                <Grid item>
                    <FormLabel className={classes.label}>Compensación</FormLabel>
                </Grid>
                <Grid item className={classes.value}>
                    <FormLabel className={classes.value}>{formatNumber(data.SueldoCompensacion, 'es-MX', '$')}</FormLabel>
                </Grid>
            </Grid>
        </PaperCard>
    )
}
export default Index

