import React, { useContext } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import AuthContext from '../components/auth/context'
import SignUpView from '../views/sign-up'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    spinner: {
        textAlign: "center"
    }
}));

const SignUp = props => {
    const classes = useStyles()
    const [setAction] = React.useState({
        signin: true,
        forgetpassword: false
    })
    const { login } = useContext(AuthContext)
    const onChangeView = (newAction) => {
        setAction(prev => ({
            ...prev,
            ...newAction
        }))
    }
    
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <SignUpView onChangeView={onChangeView} classes={classes} login={login} />
        </Container>
    )
}

export default SignUp
