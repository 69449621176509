import React from 'react';
import Helmet from 'react-helmet';
import Heading from '../components/typography/heading';
import { useAuth } from '../components/auth/useAuth';
import AuthContext from '../components/auth/context';
import LoanApplicationView from '../views/loan-application';

const LoanApplication = (props) => {
    const { UserInfo } = React.useContext(AuthContext);
    const userAuth = useAuth(UserInfo());
    return (
        <>
            <Helmet title={'Registro de una Solicitud'} />
            <Heading text={"Registro de una Solicitud"} />
            {userAuth && userAuth.sub && <LoanApplicationView userAuth={userAuth} />}
        </>
    )
}

export default LoanApplication
