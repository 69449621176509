import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';

export const styles = {
    /* Styles applied to the root element. */
    root: {
        overflowX: 'auto',
    },
};

const TableResponsive = React.forwardRef(function TableResponsive(props, ref) {
    const { classes, className, ...other } = props;

    return <div className={clsx(classes.root, className)} ref={ref} {...other} />;
});

TableResponsive.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
};

export default withStyles(styles, { name: 'MuiTableResponsive' })(TableResponsive);