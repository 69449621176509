import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}
const useApplicationHistory = (userId) => {
    return useQuery([`/api/solicitudadmin/history/${userId}`], fetch);
}

export default useApplicationHistory




