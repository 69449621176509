import React from 'react';
import Helmet from 'react-helmet';
import Employee from '../views/employee';
import Heading from '../components/typography/heading';
import { useAuth } from '../components/auth/useAuth';
import AuthContext from '../components/auth/context';

const Profile = () => {
  const { UserInfo } = React.useContext(AuthContext);
  const userAuth = useAuth(UserInfo());
  return (
      <>
        <Helmet title={'Perfil del Empleado'} />
        <Heading text={"Perfil del Empleado"} />
        {userAuth && userAuth.sub && <Employee userAuth={userAuth}/>}
      </>
  );
};

export default Profile;

