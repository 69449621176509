import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetch = async (api) => {
    try{
        const response = await fetcherReactQuery(api ? api : null)
        return response.data
    }catch(err)
    {
        console.log(err.message)
    }
    
}
const useEstadoCivil = () => {
    return useQuery([`/api/estadosciviles/active`], fetch, {
        staleTime: 30000
    })
}
export default useEstadoCivil