import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { formatNumber } from 'intl-number-helper'
import useQuarterlyReport from '../../../components/hooks/useQuarterlyReport'
import { NetDateToString } from '../../../utils/format'
import TableResponsive from '../../../components/tableresponsive'
import withStyles from '@material-ui/styles/withStyles'

const GetSaldoAcumulado = (data) => {
    return data.map(item => item.Importe).reduce((acc, val) => acc + val, 0)
}

const ReportTable = props => {
    const { classes, period1, period2 } = props
    const { data, error, isLoading } = useQuarterlyReport(period1.ID)

    if (error)
        return <p>error</p>

    if (isLoading)
        return <CircularProgress />

    return (
        <TableResponsive>
            <Table aria-label="amortization table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" className={classes.header}>#</TableCell>
                        <TableCell align="center" className={classes.header}>CATORCENA</TableCell>
                        <TableCell align="right" className={classes.header}>AFILIADOS</TableCell>
                        <TableCell align="right" className={classes.header}>APORTACIÓN</TableCell>
                        <TableCell align="right" className={classes.header}>INTERESES</TableCell>
                        <TableCell align="right" className={classes.header}>IMPORTE</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={-1}>
                        <TableCell align="center" colSpan={5}>{`Total acumulado al día ${NetDateToString(period1.RangoFechas2, "dd MMMM yyyy")}`}</TableCell>
                        <TableCell align="right">{formatNumber(data ? data.length > 0 ? data[0].SaldoAcumuladoAnterior : 0 : 0, 'en-US', '$')}</TableCell>
                        
                    </TableRow>
                    {data.map((row, index) => {
                        return (
                            <TableRow key={row.NoNomina}>
                                <TableCell align="left" component="th" scope="row">{row.NoNomina}</TableCell>
                                <TableCell align="center">{row.Catorcena}</TableCell>
                                <TableCell align="right">{formatNumber(row.Afiliados, 'en-US', '')}</TableCell>
                                <TableCell align="right">{formatNumber(row.Aportacion, 'en-US', '$')}</TableCell>
                                <TableCell align="right">{formatNumber(row.Interes, 'en-US', '$')}</TableCell>
                                <TableCell align="right">{formatNumber(row.Importe, 'en-US', '$')}</TableCell>
                            </TableRow>
                        )
                    }
                    )}
                    <TableRow key={-1}>
                        <TableCell align="center" colSpan={5}>{`Total acumulado al día ${NetDateToString(period2.RangoFechas2, "dd MMMM yyyy")}`}</TableCell>
                        <TableCell align="right">{formatNumber(GetSaldoAcumulado(data), 'en-US', '$')}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableResponsive>
    )
}

ReportTable.propTypes = {
    period: PropTypes.object,
    classes: PropTypes.object.isRequired
}

export default withStyles(withStyles)(ReportTable)
