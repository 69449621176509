import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import indigo from '@material-ui/core/colors/indigo'
import AuthContext from './components/auth/context'
import { login, logout, isLogedIn, UserInfo, getToken } from './services/auth'
import 'react-toastify/dist/ReactToastify.css'


import { ToastContainer } from "react-toastify"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const theme = createMuiTheme({
  palette: {
    primary: indigo,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthContext.Provider value={{ login, logout, isLogedIn, UserInfo, getToken }}>
      
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer style={{ fontSize: "14px" }} autoClose={2000} closeOnClick draggable hideProgressBar
            newestOnTop pauseOnHover position="top-right" rtl={false} />
          <App />
        </ThemeProvider>
      
    </AuthContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
reportWebVitals();
