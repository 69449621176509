import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, TextField } from '@material-ui/core'
import { Search as SearchIcon}  from '@material-ui/icons'

const Search = props => {
    const { onSearchChange } = props
    const [employeeNum, setEmployeeNum] = React.useState("")
    const numEmployeeHandler = (e) => {
        setEmployeeNum(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        onSearchChange(employeeNum)
    }

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField value={employeeNum}
                        label="Número de empleado"
                        fullWidth
                        id={"employeeNum"}
                        name={"employeeNum"}
                        onChange={numEmployeeHandler}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        startIcon={<SearchIcon />}
                        size="medium"
                        color="primary"
                        variant="contained"
                        type="submit">Buscar
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

Search.propTypes = {
    onSearchChange: PropTypes.func
}

export default Search
