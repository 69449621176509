import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Format } from '../../utils/format';
import { formatNumber } from 'intl-number-helper'
import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableResponsive from '../../components/tableresponsive'

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: "bold"
    }
}))
const AccountStatusTable = (props) => {
    const { data } = props
    const classes = useStyles()
    return (
        <TableResponsive>
            <Table style={{ overflowX: "auto" }} aria-label="account status">
            <TableHead>
                <TableRow>
                    <TableCell align="center" className={classes.header}>Fecha </TableCell>
                    <TableCell className={classes.header}>Concepto</TableCell>
                    <TableCell align="right" className={classes.header}>Cargo</TableCell>
                    <TableCell align="right" className={classes.header}>Abono</TableCell>
                    <TableCell align="right" className={classes.header}>Saldo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(data || []).map((row, index) => (
                    <TableRow key={index}>
                        <TableCell align="center" component="th" scope="row">
                            {Format(row.Fecha)}
                        </TableCell>
                        <TableCell align="left">{row.Concepto}</TableCell>
                        <TableCell align="right">{formatNumber(row.Cargo, 'en-US', '$')}</TableCell>
                        <TableCell align="right">{formatNumber(row.Abono, 'en-US', '$')}</TableCell>
                        <TableCell align="right">{formatNumber(row.Saldo, 'en-US', '$')}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </TableResponsive>
    )
}

AccountStatusTable.propTypes = {
    data: PropTypes.array
}

export default memo(AccountStatusTable);