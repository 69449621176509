import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Delete, Close as CloseIcon, Done as DoneIcon, CameraAlt as CameraIcon, CloudUpload } from '@material-ui/icons';
import {
    Box,
    Grid,
    Fab,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton, Card, CardActions, CardContent
} from '@material-ui/core';
import PopUp from 'reactjs-popup';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { toast } from 'react-toastify';

const feature_camera = false;

const Documents = ({ onDoctosChange, IndTrasferencia, data }) => {
    const [imageDataURL, SetImageDataURL] = React.useState(null);
    const [isOpen, SetIsOpen] = React.useState(false);
    const fileInput = useRef(null);

    const [doctos, SetDoctos] = React.useState([]);

    React.useEffect(() => {
        if (data) {
            const filtered = IndTrasferencia ?
                data : data.filter(w => !w.Documento.Descripcion.includes("ESTADO DE CUENTA BANCARIA"));


            SetDoctos(filtered.map(docto => {
                return {
                    id: docto.Id,
                    name: docto?.Documento?.Descripcion,
                    done: false,
                    selected: false,
                    imageDataURL: null,
                    documentoId: docto.DocumentoId,
                    indTransferencia: docto.IndTransferencia,
                    tipoPrestamo: docto.TipoPrestamo
                }
            }).sort(compare));

        }
    }, [IndTrasferencia]);


    const compare = (a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const onOpen = (id) => {
        const selected = doctos
            .filter(filter => filter.id === id)
            .map(map => {
                return {
                    ...map,
                    selected: true
                }
            });
        SetDoctos(prev => [...prev.filter(f => f.id !== id), ...selected].sort(compare));
        SetIsOpen(true);
    }

    const onDelete = (id) => {
        const selected = doctos
            .filter(filter => filter.id === id)
            .map(map => {
                return {
                    ...map,
                    done: false,
                    selected: false,
                    imageDataURL: null
                }
            });
        SetDoctos(prev => [...prev.filter(f => f.id !== id), ...selected].sort(compare));
        if (onDoctosChange)
            onDoctosChange([...doctos.filter(f => f.id !== id), ...selected].sort(compare));
        SetIsOpen(false);
        SetImageDataURL(null);
    }

    const pictureTaken = () => {

        if (!imageDataURL)
            return

        const selected = doctos
            .filter(filter => filter.selected === true)
            .map(map => {
                return {
                    ...map,
                    done: true,
                    selected: false,
                    imageDataURL
                }
            });
        SetDoctos(prev => [...prev.filter(f => f.selected !== true), ...selected].sort(compare));
        SetIsOpen(false);
        SetImageDataURL(null);
        if (onDoctosChange)
            onDoctosChange([...doctos.filter(f => f.selected !== true), ...selected].sort(compare));
    }

    const onUploadBegin = (id) => {
        const selected = doctos
            .filter(filter => filter.id === id)
            .map(map => {
                return {
                    ...map,
                    selected: true
                }
            });
        SetDoctos(prev => [...prev.filter(f => f.id !== id), ...selected].sort(compare));
    }

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if ((file.size / 1000) > (1024 * 2.5)) {
            toast.warning("El archivo no debe de exceder de 2.5MB", {
                autoClose: 5000
            });
            return;
        }

        const imageURI = await toBase64(e.target.files[0]);
        const selected = doctos
            .filter(filter => filter.selected === true)
            .map(map => {
                return {
                    ...map,
                    done: true,
                    selected: false,
                    imageDataURL: imageURI
                }
            });
        SetDoctos(prev => [...prev.filter(f => f.selected !== true), ...selected].sort(compare));
        SetImageDataURL(null);
        if (onDoctosChange)
            onDoctosChange([...doctos.filter(f => f.selected !== true), ...selected].sort(compare));

    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    return (
        <div>
            <PopUp modal
                closeOnDocumentClick={false}
                open={isOpen}>
                <Card style={{ boxShadow: "5px 10px 20px silver", marginTop: 20 }} variant="outlined">
                    <CardContent>
                        <Camera
                            idealFacingMode={true}
                            imageCompression={1}
                            imageType={'jpg'}
                            onTakePhoto={(dataUri) => {
                                SetImageDataURL(dataUri);
                            }}
                        />
                    </CardContent>
                    <CardActions>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent={"center"}>
                                <Fab style={{ marginRight: 10 }} type="button"
                                    size="medium"
                                    color="primary"
                                    title="Aceptar"
                                    onClick={e => {
                                        pictureTaken();
                                    }}
                                >
                                    <DoneIcon />
                                </Fab>

                                <Fab type="button"
                                    size="medium"
                                    color="secondary"
                                    title="Cancelar"
                                    onClick={e => {
                                        SetIsOpen(false);
                                        SetImageDataURL(false);
                                    }}
                                >
                                    <CloseIcon />
                                </Fab>
                            </Box>
                        </Grid>
                    </CardActions>
                </Card>
            </PopUp>

            <input
                style={{ display: "none", width: 10, height: 10 }}
                id="file"
                name="file"
                type="file"
                ref={ref => {
                    fileInput.current = ref;
                }}
                onChange={handleUpload}
            />

            <Grid item xs={12}>
                <List>
                    {doctos?.map(({ id, name, done, Comentario: comentario }) => {
                        const labelId = `checkbox-list-label-${id}`;
                        return (
                            <ListItem key={id} role={undefined} dense button>
                                <ListItemText
                                    style={{
                                        textDecorationColor: done ? "#16825D" : "inherit",
                                        textDecorationLine: done ? "line-through" : "none",
                                        textDecorationThickness: "2.5px",
                                    }} id={labelId}
                                    primary={<span style={{ fontSize: 12.5, fontWeight: "bold" }}>{name}</span>}
                                    secondary={comentario}
                                />
                                <ListItemSecondaryAction style={{ right: -30 }}>
                                    {feature_camera &&
                                        <IconButton onClick={e => onOpen(id)} color="primary" edge="end" aria-label="take">
                                            <CameraIcon />
                                        </IconButton>
                                    }
                                    <IconButton onClick={e => {
                                        fileInput.current && fileInput.current.click()
                                        onUploadBegin(id);
                                    }}
                                        color="default" edge="end" aria-label="take">
                                        <CloudUpload />
                                    </IconButton>
                                    <IconButton onClick={e => onDelete(id)} color="secondary" edge="end" aria-label="delete">
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
        </div>
    );
}

Documents.default = {
    IndTrasferencia: false,
}

Documents.propTypes = {
    data: PropTypes.array.isRequired,
    onDoctosChange: PropTypes.func.isRequired,
    IndTrasferencia: PropTypes.bool.isRequired
}

export default Documents;