import React from 'react'
import SignIn from './pages/sign-in'
import SignUp from './pages/sign-up'
import Layout from './layouts/Layout'
import { createBrowserHistory } from 'history'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

const history = createBrowserHistory()
const baserUrl = document.getElementsByTagName("base")[0].getAttribute('href')

const App = () => {
  
  return (
    <div className="App">
      <Router history={history} basename={baserUrl} > 
        <Switch>
          <Route path="/admin">
            <Layout />
          </Route>
          <Route path="/sign-in" exact>
            <SignIn />
          </Route>
          <Route path="/sign-up" exact>
            <SignUp />
          </Route>
          <Route path="*">
            <SignIn />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App
