// import { fetcherSWR } from '../../services/api/fetcher'
// import useSWR from 'swr'

// const fetcher = async (api) => {
//     return await fetcherSWR(api)
// }

// const useAccountStatus = (empresaConvenioId, userId, financialProductId) => {
//     return useSWR(financialProductId ? [`/api/GetEstadoCuenta/${empresaConvenioId}/${userId}/${financialProductId}`] : null, fetcher)
// }

// export default useAccountStatus

import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetch = async (api) => {
    try {
        const response = await fetcherReactQuery(api)
        return response.data
    }catch(err)
    {
        console.log(err)
        return []
    }
}
const useAccountStatus = (empresaConvenioId, userId, financialProductId) => {
    return useQuery([`/api/GetEstadoCuenta/${empresaConvenioId}/${userId}/${financialProductId}`], fetch)
}
export default useAccountStatus